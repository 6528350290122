@import url(./master/normalize.scss);

@import url(./master/colors.scss);
@import url(./master/variables.scss);
@import url(./master/extenders.scss);
@import url(./responsive.scss);

@import url(./print.scss);
@import url(./font.scss);

@import url('__ICONS_SCSS__');
//@import url(@sunwing/icons/dist/sunwing/scss/icons.scss);
//@import url(@sunwing/icons/dist/vacances-westjet-quebec/scss/icons.scss);

* {
  box-sizing: border-box;
}

@media screen {

  html[data-brand=vwq] {
    body {
      font-family: "Noto Sans", "Noto Serif" !important;
    }
  }

  html[data-brand=swg] {
    body {
      font-family: "PT Sans", sans-serif !important;
    }
  }

  html,
  body {
    color: var(--body-fg) !important;
    background-color: var(--white);
    font-size: var(--grid-1x) !important;
    line-height: var(--grid-2x) !important;
    height: 100%;
    scroll-behavior: smooth;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    line-height: var(--grid-2x);
    font-size: var(--grid-1x) !important;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .base-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main {
    flex: 1;
  }

  .banner-img {
    width: 100%;
    height: 80px;
  }

  .promotions-row-scroll {
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 250px);
    overflow-y: auto;
  }

  .overlay h1 {
    margin-bottom: 24px;
  }

  .banner h1 {
    margin-bottom: 0!important;
  }

  .overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    overflow-y: auto;
    // margin: 0 auto;
    width: 100%;
    padding: 30px;
    height: 100vh;
    background: var(--onyx-bg);
 }

  .pop-over-bg .MuiButton-root, .overlay .MuiButton-root {
    width: auto!important;
    min-width: auto!important;
    padding: 0!important;
  }

  .close-btn-row {
    width: 100%;
    margin-bottom: 16px;
    overflow: hidden;
    text-align: right;
  }

  .pop-over-bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: var(--onyx-bg);
    box-sizing: border-box;
    padding: var(--grid-1x);
  }

  .pop-over-bg .banner, .overlay .banner {
    border-bottom-right-radius: 24px;
  }

  .loader-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #ffffff79;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .slick-arrow {
    margin-top: var(--grid-1x);
  }

  .MuiSvgIcon-root.slick-arrow {
    color: var(--slick-arrow-fg) !important
  }

  .slick-next {
    right: -24px !important;
  }

  .slick-prev {
    left: -24px !important;
  }

  .is-mobile {
    display: block;
  }

  .is-desktop {
    display: none;
  }

  .show-pricing-notes, .show-terms-and-condition {
    display: none;
  }

  .pricing-notes-text, .terms-and-conditions-text {
    display: block;
  }

  .slick-prev,
  .slick-next {
    width: 32px !important;
    height: 32px !important;
    top: 44px !important;
  }

  .border-top {
    border-top: 1px solid var(--onyx-brdr);
  }

  .border {
    border: 1px solid var(--onyx-brdr);
  }

  .thumbnail {
    width: 240px;
    height: 160px;
  }

  .slick-slide .col-3 {
    padding-top: var(--grid-1x);
  }

  .styles_scroll-to-top__2A70v {
    width: var(--grid-4x) !important;
    height: var(--grid-4x) !important;
    border-radius: 4px !important;
    padding: var(--grid);
    border: 1px solid var(--orange) !important;
  }

  .styles_scroll-to-top__2A70v svg {
    width: var(--grid-2x) !important;
    height: var(--grid-2x) !important;
  }

  .page-header-container a,
  .page-header-container a:active,
  .page-header-container a:visited,
  .page-header-container a:hover {
    color: var(--page-header-container-hyper-link-fg) !important;
  }

  .success {
    color: var(--coral-reef);
  }

  .MuiButton-root {
    //font-family: "PT Sans", sans-serif !important;
    text-transform: initial !important;
    min-width: calc(var(--grid-10x)) !important;
    font-size: var(--grid-1x) !important;
    letter-spacing: 0px!important;
  }

  .MuiButton-containedPrimary {
    background: var(--mui-button-contained-primary-bg) !important;
    height: 38px;
  }

  .MuiButton-outlined {
    height: 38px;
    font-size: var(--grid-1x) !important;
    color: var(--mui-button-outlined-fg) !important;
    border: 1px solid var(--mui-button-outlined-fg) !important;
  }

  .btn-white {
    height: 38px;
    background-color: var(--white) !important;
    color: var(--btn-white-fg) !important;
    border: 1px solid var(--white) !important;
    font-size: calc(var(--grid) * 2) !important;
  }

  .btn-red {
    height: 38px;
    background-color: var(--white) !important;
    color: var(--error-fg) !important;
    border: 1px solid var(--error-fg) !important;
    font-size: calc(var(--grid) * 2) !important;
  }

  .btn-blue {
    height: 38px;
    background-color: var(--white) !important;
    color: var(--btn-blue-fg) !important;
    border: 1px solid var(--btn-blue-fg) !important;
    font-size: calc(var(--grid) * 2) !important;
  }

  label,
  .MuiFormHelperText-root {
    margin-bottom: calc(var(--grid) / 2);
    color: var(--onyx) !important;
    //font-family: "PT Sans", sans-serif !important;
    font-size: 14px !important;
  }

  ul {
    padding: 0;
    margin: 0;
  }
  
  .terms-and-conditions-container li, .group-contract-row li {
    margin: 8px 16px;
  }

  .additional-policies-container .group-contract-row {
    padding: 8px 16px;
    box-sizing: border-box;
  }

  .box-sizing {
      box-sizing: border-box;
  }

  .error > fieldset {
      border: 1px solid var(--error-fg);
  }

  .font-icon {
    color: var(--font-icon);
  }
  
  .font-orange {
      color: var(--orange);
  }

  .font-white {
      color: var(--white);
  }

  .font-blue {
      color: var(--font-blue);
  }

  .font-light-grey {
      color: var(--onyx-brdr);
  }

  .font-red {
      color: var(--error-fg);
  }

  .font-green {
      color: var(--coral-reef);
  }

  .errorControl fieldset {
    border: 1px solid var(--error-fg);
  }

  .showOrHide {
    display: none;
  }

  .margin-top-negative-4 {
      margin-top: -4px;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-style {
    display:flex;
    white-space: nowrap;
    overflow:auto;
  };
  .col-5 .row label, .col-5-no-margin-r .row label, .row .value {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .col-half .row .value {
    width: 40%!important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .option-heading {
    color: var(--option-heading-fg);
    font-weight: bold;
    font-size: calc(var(--grid) * 2.5);
  }

  .MuiFormControlLabel-root .MuiTypography-root {
      color: var(--onyx) !important;
      //font-family: "PT Sans", sans-serif !important;
      font-size: var(--grid-1x) !important;
  }

  h1 {
      font-size: calc(var(--grid-4x) + (var(--grid) / 2));
      margin: 0;
      color: var(--h1-fg);
      line-height: calc(var(--grid-4x));
  }

  html[data-brand=swg] {
    h1 {
        font-family: "muller-narrow-bold";
    } 
  }

  html[data-brand=vwq] {
    h1 {
      font-family: "Noto Sans", "Noto Serif" !important;
      font-weight: 600;
      color:var(--black) !important;
    } 
    h1.font-white {
      color:var(--white) !important;
    }
  }

  h3 {
      margin: 0;
      font-weight: 300 !important;
  }

  .font-20 {
      font-size: 20px !important;
      line-height: 32px;
  }

  .font-24 {
      font-size: 24px !important;
      line-height: 32px;
  }

  .background-blue {
      background: var(--background-blue-bg);
      border-radius: 4px;
  }

  .background-white {
      background: var(--white);
      border-radius: 4px;
  }

  .font-32 {
      font-size: var(--grid-3x);
  }

  .hotel-pic {
      width: 240px;
      height: 160px;
      float: left;
      border-radius: 4px;
      margin-right: var(--grid-1x);
      background: var(--onyx-bg);
  }

  .hotel-deviation-info,
  .hotel-pic-deviation {
    width: 240px;
    height: 160px;
    float: left;
    border-radius: 4px;
    margin-right: var(--grid-1x);
    background: var(--onyx-bg);
}

.hotel-deviation-info {
  height: auto;
}
  .hotel-pic img, .hotel-pic-deviation img {
      width: 100%;
      height: 100%;
  }

  .hotel-details {
      width: 30%;
      float: left;
      margin-right: var(--grid-1x);
      box-sizing: border-box;
  }

  html[data-brand=vwq]{
    .hotel-details {
      width: 33%;
    } 
  }

  .banner {
      width: 100%;
      padding: var(--grid-1x);
      margin-bottom: 24px;
  }

  .banner-heading {
      border: 0 !important;
  }

  .container {
      width: 100%;
      background-color: var(--white);
  }

  .text-center {
      text-align: center;
  }

  .hide {
      display: none !important;
  }

  .show {
      display: block !important;
  }

  .invisible {
      opacity: 0 !important;
  }

  .visible {
      opacity: 1 !important;
  }

  .terms-and-conditions-container li {
    margin: 8px 16px;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .show-add-ons {
    display: none;
  }

  .hide-add-ons {
    display: block;
  }

  .product-types-container {
    display: block;
  }

  .background-dark {
    background: var(--starry-night);
    border-radius: 4px;
    border: 1px solid var(--starry-night);
  }

  .background-grey {
    background: var(--onyx-bg) !important;
  }

  .not-available {
    color: var(--onyx-brdr);
    font-style: italic;
  }

  .info-pop-over-row {
    width: 100%;
    padding: 8px 16px;
  }

  .font-bold {
    font-weight: 700 !important;
  }

  .margin-top {
    margin-top: var(--grid);
  }

  .margin-left {
    margin-left: var(--grid);
  }

  .margin-left-1x {
    margin-left: var(--grid-1x) !important;
  }

  .margin-left-2x {
    margin-left: var(--grid-2x);
  }

  .margin-right {
    margin-right: var(--grid);
  }

  .margin-right-1x {
    margin-right: var(--grid-1x) !important;
  }

  .margin-right-2x {
    margin-right: var(--grid-2x);
  }

  .margin-bottom-1-4 {
    margin-bottom: calc(var(--grid) / 2) !important;
  }

  .margin-bottom {
    margin-bottom: var(--grid) !important;
  }

  .margin-bottom-1x {
    margin-bottom: var(--grid-1x) !important;
  }

  .margin-bottom-2x {
    margin-bottom: var(--grid-2x);
  }

  .margin-bottom-3x {
    margin-bottom: var(--grid-3x);
  }

  .margin-bottom-4x {
    margin-bottom: var(--grid-4x);
  }

  .margin-bottom-5x {
    margin-bottom: var(--grid-5x);
  }

  .margin-top-1-4 {
    margin-top: calc(var(--grid) / 2);
  }

  .margin-top-1-6 {
    margin-top: 6px;
  }

  .MuiInputAdornment-root button, .MuiInputAdornment-root button:hover {
    background: transparent;
  }

  .MuiInputAdornment-root {
    background: var(--white);
    height: 100%!important;
    max-height: 100%!important;
  }

  .MuiInputAdornment-root .swg-icon {
    font-size: 14px;
  }

  .text-align-right .MuiOutlinedInput-input, .text-align-right {
    text-align: right;
  }

  .font-error {
    color: var(--error-fg);
  }

  .margin-top-1x {
    margin-top: var(--grid-1x);
  }

  .margin-top-2x {
    margin-top: var(--grid-2x);
  }

  .margin-top-2-5x {
    margin-top: 24px;
  }

  .margin-top-3x {
    margin-top: var(--grid-3x);
  }

  .margin-top-4x {
    margin-top: var(--grid-4x);
  }

  .margin-top-6x {
    margin-top: var(--grid-6x);
  }

  .padding-bottom-1x {
    padding-bottom: var(--grid-1x) !important;
  }

  .border-bottom {
    border-bottom: 1px dashed var(--onyx-brdr);
  }

  .section-header {
    width: 100%;
    margin-bottom: var(--grid-3x);
    text-transform: uppercase;
  }

  .grey-color-bg {
    background: var(--onyx-bg);
  }

  .border-right {
    border-right: 1px solid var(--onyx-brdr) !important;
  }

  .link-button {
    height: 16px;
    padding: 4px var(--grid-1x) !important;
    margin-top: -4px !important;
    color: var(--link-button-fg) !important;
    cursor: pointer;
    font-weight: var(--link-button-weight);
  }

  .link-row {
    width: 100%;
    height: var(--grid-5x);
    padding: var(--grid);
    border-radius: 4px;
  }

  .warning-bar {
    width: 100%;
    border-radius: 4px;
    padding: var(--grid);
    border: 1px solid var(--warning-fg);
    background: var(--warning-bg);
    overflow: hidden;
  }

  .font-yellow {
    color: var(--warning-fg);
  }

  .warning-icon {
    width: 32px;
  }

  .warning-text {
    width: calc(100% - 32px);
  }

  .isactive {
    font-weight: 700;
    position: relative;
  }

  .isactive:after {
    position: absolute;
    bottom: -26px;
    left: 50%;
    border: 10px solid transparent;
    border-bottom-color: currentcolor;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
  }

  .col-05 {
    width: calc(8.33% - var(--grid-1x));
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-05-no-margin-r {
    width: 16.66%;
    float: left;
  }

  .col-1 {
    width: calc(16.66% - var(--grid-1x));
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-1-no-margin-r {
    width: 16.66%;
    float: left;
  }

  .col-2 {
    width: calc(25% - var(--grid-1x));
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-2-no-margin-r {
    width: 25%;
    float: left;
  }

  .col {
    width: calc(33.33% - var(--grid-1x));
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-1-7 {
    width: 14%;
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-1-6 {
    width: 16.667%;
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-1-8 {
    width: 12.5%;
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-1-10 {
    width: 10.0%;
    float: left;
    margin-right: var(--grid-1x);
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .col-1-10 {
        width: 31.1% !important;
    }
  }
  .col-1-12 {
    width: 8.3%;
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-3 {
    width: calc(33.33% - var(--grid-1x));
    float: left;
    margin-right: var(--grid-1x);
    min-height: var(--grid-11x);
  }

  .summary .col-3 {
    min-height: inherit;
  }

  .col-3-blank {
    width: calc(33.33% - var(--grid-1x));
    /* min-height: var(--grid-10x); */
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-6 {
    width: calc(66.66% - var(--grid-1x));
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-6-no-margin-r {
    width: 66.66%;
    float: left;
  }

  .col-5 {
    width: calc(50% - var(--grid-1x));
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-5-no-margin-r {
    width: 50%;
    float: left;
  }

  .col-option-text {
    width: 100px;
  }

  .col-option-gateway {
    width: 50%;
    text-align: right;
  }

  .col-35 {
    width: 35%;
    float: left;
  }

  .col-30 {
    width: 30%;
    float: left;
  }

  .col-3-no-margin-r {
    width: 33.33%;
    float: left;
  }

  .delete-link {
    color: var(--onyx2) !important;
  }

  .button-center {
    display: flex;
    justify-content: center;
  }

  .flex-aside {
    display: flex;
    justify-content: space-between;
  }

  .button-center .next-link-button {
    font-weight: 400 !important;
  }

  .date-range {
    width: calc(100%);
    padding: 5px 6px;
  }

  .duration {
    width: 80px;
    height: 40px;
    text-align: right;
    border: 0 solid var(--onyx-brdr);
    padding: 6px 0 0 4px;
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--onyx3);
  }

  .slider {
    width: calc(100% - 2px) !important;
  }

  .row .heading {
    width: 40%;
    float: left;
  }

  .font-size-14 {
    font-size: calc(var(--grid) * 1.75);
  }

  .row .value {
    width: 60%;
    float: left;
  }

  .view-quote .heading {
    width: 40%;
  }
  .deviation-date-range{
    width: 480px;
  }
  .view-quote .value {
    width: 60%;
  }

  .col-50-percent {
    width: 50%;
    float: left;
  }

  .app-container {
    width: 100%;
    padding: var(--grid-4x) var(--grid-14x);
    box-sizing: border-box;

    background-color: var(--white);
  }

  .app-container Section:first-child {
    padding-top: 0 !important;
  }

  .page-main-container {
    width: 100%;
    padding: var(--grid-1x) 0 0 0;
    box-sizing: border-box;

    background-color: var(--white);
  }

  .next-link-button {
    background-color: transparent;
    border: none;
    font-weight: 700 !important;
    text-decoration: none;
    color: var(--next-link-button-fg) !important;
    text-transform: none !important;
    cursor: pointer;
    min-width: 0 !important;
    width: auto !important;
    background: transparent !important;
  }
 
  .cancel-link-button {
    background-color: transparent;
    border: none;
    /* font-weight: 700 !important; */
    text-decoration: none;
    color: var(--ocean-blue) !important;
    text-transform: none !important;
    cursor: pointer;
    min-width: 0 !important;
    width: auto !important;
    background: transparent !important;
    margin-top: 2px;
}
  .button-disabled {
    background: transparent !important;
    color: var(--onyx-brdr);
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .sticky Section,
  .view-quote-container Section {
    padding-top: 0 !important;
  }

  .pull-right {
    float: right;
  }

  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .row {
    width: 100%;
    box-sizing: border-box;
  }

  .row::after {
    content: "";
    clear: both;
    display: block;
  }

  .view-quote Section {
      padding-top: 0!important;
      padding-bottom: 0!important;
  }

  .font-16 {
      font-size: var(--grid-1x);
  }

  .font-14 {
      font-size: calc(var(--grid-1x) - 2px);
  }

  select {
      background-color: var(--white);
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjM5Ni42LDE2MCA0MTYsMTgwLjcgMjU2LDM1MiA5NiwxODAuNyAxMTUuMywxNjAgMjU2LDMxMC41ICIvPjwvc3ZnPg==");
      background-position: right 10px center;
      background-repeat: no-repeat;
      background-size: auto 50%;
      border-radius: 2px;
      border: none;
      padding: 10px 40px 10px 10px;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
  }

  input[type="radio"] {
      background-color: var(--white);
      border: 1px solid var(--onyx2);
      border-radius: 50%;
      box-shadow: inset 0 0 0 0 white;
      cursor: pointer;
      font: inherit;
      height: 0.8em;
      outline: none;
      width: 0.8em;
      -moz-appearance: none;
      -webkit-appearance: none;
  }

  input[type="radio"]:checked {
      background-color: white;
      box-shadow: inset 0 0 0 0.1875em #595959;
  }

  input[type="radio"]:checked {
      background-color: var(--white);
      box-shadow: inset 0 0 0 0.1875em var(--onyx2);
  }

  .Mui-checked {
      color:var(--mui-checked-fg) !important;
  }

  .MuiRadio-root {
      padding: 0 var(--grid) 0 var(--grid) !important;
  }

  .MuiFormControlLabel-root {
      padding-right: var(--grid-1x) !important;
  }

  .MuiCheckbox-root {
      padding: var(--grid) !important;
  }

  .search-row .MuiFormControlLabel-root {
      padding-right: 0 !important;
      margin-right: 0 !important;
  }

  .MuiOutlinedInput-input,
  .MuiSelect-select {
      //font-family: "PT Sans", sans-serif !important;
      padding: var(--grid) !important;
      box-sizing: border-box;
  }

  .wizard-container {
      width: 100%;
      padding: var(--grid-2x);
      box-sizing: border-box;

      -webkit-border-bottom-right-radius: calc(var(--grid) / 2);
      -webkit-border-bottom-left-radius: calc(var(--grid) / 2);
      -moz-border-radius-bottomright: calc(var(--grid) / 2);
      -moz-border-radius-bottomleft: calc(var(--grid) / 2);
      border-bottom-right-radius: calc(var(--grid) / 2);
      border-bottom-left-radius: calc(var(--grid) / 2);
  }

  .wizard-footer {
      width: 100%;
      padding: var(--grid-2x);
      box-sizing: border-box;
  }

  .Mui-disabled {
      -webkit-text-fill-color: var(--black) !important;
      background: var(--mui-disabled-bg) !important;
  }

  input.Mui-disabled {
    background: transparent !important;
  }

  .wizard-button .Mui-disabled {
      background: transparent !important;
      color: var(--onyx3) !important;
  }

  .DateInput_input__focused {
      border: none !important;
  }

  .DateInput_input {
      //font-family: "PT Sans";
      border: 0 !important;
      padding: 0 !important;
      color: var(--onyx) !important;
      font-weight: 400 !important;
      background: var(--white);
      font-size: var(--grid-1x) !important;
  }

  .DateInput {
      width: 104px !important;
      padding: 0 var(--grid) !important;
  }

  .pull-left {
      float: left;
  }

  .DateRangePicker {
      display: inherit !important;
  }

  .DateRangePickerInput__withBorder {
      display: inherit !important;
      border: 0 !important;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
      border-right-color: var(--ocean-blue) !important;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
      background: var(--onyx) !important;
      border: 1px double var(--onyx) !important;
      color: var(--white) !important;
  }

  .CalendarDay__selected_span {
      background: var(--onyx-brdr) !important;
      border: 1px double var(--onyx-brdr) !important;
      color: var(--onyx) !important;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
      display: none;
  }

  .row-additional-information {
      width: 100%;
      border: 1px solid var(--onyx-brdr);
      border-radius: 4px;
      padding: var(--grid);
      box-sizing: border-box;
      word-wrap: break-word;
  }
  .row-additional-information.generic-contract{
    border: none;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--mui-focused-outline-input-notched) !important;
  }

  .row-additional-information .MuiOutlinedInput-notchedOutline {
      border: 0 !important;
  }

  .font-grey {
      color: var(--onyx2);
  }

  .Mui-error {
      /* position: absolute; */
      color: var(--error-fg) !important;
  }

  .Localize-text {
      font-size: 14px !important;
      margin-bottom: calc(var(--grid) / 2);
  }

  .popup-heading .Localize-text, .MuiSelect-select .Localize-text {
      font-size: inherit !important;
      margin-bottom: 0 !important;
  }

  .wizard-footer .Localize-text {
      font-size: var(--grid-1x) !important;
      margin-bottom: 0 !important;
  }

  .box-container .Localize-text {
      font-size: var(--grid-1x) !important;
      margin-bottom: 0 !important;
  }

  Button .Localize-text,
  .search-text .Localize-text {
      font-size: inherit !important;
      margin-bottom: 0;
  }

  .Mui-error .MuiOutlinedInput-input {
      color: var(--onyx2) !important;
  }

  .arrow-l,
  .arrow-r {
      font-weight: 700 !important;
      font-size: calc(var(--grid) * 1.5) !important;
  }

  .arrow-l {
      margin-right: var(--grid) !important;
  }

  .arrow-r {
      margin-left: var(--grid) !important;
  }

  .icon-24 {
      font-size: var(--grid-2x) !important;
  }

  .icon-16 {
      font-size: var(--grid-1x) !important;
  }

  .icon-12 {
      font-size: calc(var(--grid) + 4px) !important;
  }

  html[data-brand=swg] {
    .rotate-90 {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

  .rotate-180 {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
  }

  .loading-image-center {
      width: 200px;
      height: 200px;
      margin: 0 auto;
  }

  .loading-image-center img {
      width: 100%;
  }

  .box-container {
      margin: 10% 0 20% 0;
  }

  .MuiInputBase-root {
    height: 39px;
    padding: 0 !important;
  }

  .Mui-focused fieldset {
    border: solid 1px var(--mui-focused-br) !important;
  }

  .number-fields-margin-top {
    margin-top: 2px;
  }

  .duration-fields-margin-top {
    margin-top: 3px;
  }

  .heart {
    background-color: var(--heart-bg);
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 10px;
    position: relative;
    top: 0;
    transform: rotate(-45deg);
  }

  .heart:before,
  .heart:after {
    content: "";
    background-color: var(--heart-bg);
    border-radius: 50%;
    height: 12px;
    width: 12px;
    position: absolute;
  }

  .heart:before {
    top: -8px;
    left: 0;
  }

  .heart:after {
    left: 8px;
    top: 0;
  }

  .popup-container-bg {
    width: 100%;
    height: 100vh;
    background: var(--onyx-opacity-90);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 998;
  }

  body.popup-open {
    overflow: hidden;
  }

  .view-quote-container-bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 998;
  }

  .popup-container {
    width: 700px;
    padding: var(--grid-1x);
    background: var(--white);
    position: fixed;
    z-index: 999;
    border-radius: 4px;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }

  .add-quote-modal {
    padding: 16px!important;
  }

  .add-quote-modal h3 {
    margin-bottom: 16px;
  }

  .col-half {
    width: 50%;
    float: left;
  }

  .col-one-third {
    width: 33.33%;
    float: left;
  }

  .add-quote-modal.popup-container {
    width: 1120px;
    max-height: 85vh;
    overflow-y: auto;
    padding: 0;
  }

  .add-quote {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    background: var(--onyx-bg);
  }

  .add-quote .profile-information-container {
    margin-bottom: 0;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    border-radius: calc(var(--grid) / 2);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border: 1px solid var(--white);
    background: #f1f1f1;
    border-radius: calc(var(--grid) / 2);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 1px solid var(--white);
    background: var(--onyx);
    border-radius: calc(var(--grid) / 2);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    width: 8px;
    background: var(--onyx);
  }

  .popup-body {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--onyx-brdr);
    border-radius: 4px;
    padding: var(--grid-1x);
    color: var(--onyx);
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .popup-body .MuiButton-root {
    height: 40px;
  }

  .popup-heading {
    font-size: var(--grid-2x);
    font-weight: 700;
  }

  .popup-container-border {
      width: 100%;
      padding: var(--grid-1x);
      box-sizing: border-box;
      border: 1px solid var(--onyx3);
      border-radius: 4px;
  }

  .popup-heading-1x {
      font-size: 18px;
      font-weight: 700;
  }

  .popup-heading-1x .Localize-text {
      font-size: inherit !important;
      margin-bottom: 0 !important;
  }
}

/* Tablets */
@media screen and (max-width: 820px) {
  .app-container {
    padding: var(--grid-4x) var(--grid-3x);
  }

  .font-size-14 {
    font-size: calc(var(--grid) * 1.5);
  }

  .col-05 {
    width: 48%;
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-05-no-margin-r {
    width: 48%;
    float: left;
  }

  .col-1 {
    width: 48%;
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-1-no-margin-r {
    width: 48%;
    float: left;
  }

  .col-2 {
    width: 47%;
    float: left;
    margin-right: var(--grid-1x);
  }

  .col-2-no-margin-r {
    width: 48%;
    float: left;
  }
}

@media screen and (max-width: 1024px) {
  .app-container {
    padding: var(--grid-4x) var(--grid-3x);
  }

  .font-size-14 {
    font-size: calc(var(--grid) * 1.75);
  }

  .col-sm-1 {
    width: calc(33% - var(--grid-2x));
    float: left;
    margin-right: var(--grid-2x);
  }

  .col-sm-1r0 {
    width: calc(33% - var(--grid-2x));
    float: left;
    margin-right: 0px;
  }
}
@media screen and (max-width: 631px) { 
  .col-2 {
    width: 100%; 
  }
}

.root-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.815);
  z-index: 9999;
  .loader-image{
      /* width: 210px; */
      height: 150px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -100px;
      margin-left: -200px;
  }
}
.is-mobile-screen {
  display: none;
}
  .email-logo-cover{
    background-image: url('../assets/images/email-send.gif');
    background-size: 160px;
    background-repeat: no-repeat;
    height: 160px;
    width: 200px;
    position: relative;
    left: 50%;
    margin-left: -90px;
  }
.email-popup-container {
  text-align: center;

}
.email-declaration-text{
  font-size: 16px;
}
.close-popup-button {
  color: #a1a1a1;
  text-align: right;
}
.close-popup-button:hover {
  cursor: pointer
}


.lds-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.lds-roller {
  top: 50%;
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  margin-left: -40px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ff6000d9;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.price-details-container {
  .col-3.col-left-price-value{
    width: 33%;
    margin-right: 0 !important;
  } 
  .col-6.col-left-price-details{
    width: 62%;
  } 
}
.go-back-btn{
  margin-top: 16px !important;
}
.warning-alert-prompt{
  height: 40px;
  font-size: 40px !important;
  color: #ffb76d;
  margin-bottom: 16px;
}

.d-flex {
  display: flex !important;
}

.d-flex.gap {
  gap: 10px;
}

input:disabled {
  cursor: not-allowed;
}

input[type=text]:disabled {
  background-color: var(--input-disabled-bg) !important;
  border-radius: 4px !important;
  border-width: 1px !important;
}

.yes-no-option-btn{
  .MuiButtonBase-root{
      padding: 4px 16px;
      border-radius: 4px;
  }
  .no-btn{
      color: var(--no-btn-fg);
  }
  .yes-btn{
      color: var(--white);
  }

}

.cancel-button {
  height: 38px;
  background-color: var(--white) !important;
  color: var(--error-fg) !important;
  border: 1px solid var(--white) !important;
}

html[data-brand=vwq] {

  @import "@sunwing/icons/vacances-westjet-quebec/scss/icons";

  .vwq-icon-passengers {
    @include vwq-icon(companion, false);
  }
  .vwq-icon-map
  {
      @include vwq-icon(pin, false);
  }
  .vwq-icon-promotion
  {
    @include vwq-icon(announcement, false);
  }
  .vwq-icon-checked--n
  {
    @include vwq-icon(checkmark, false);
  }
  .vwq-icon-packages
  {
    @include vwq-icon(sunny, false);
  }
  .vwq-icon-price--n
  {
    @include vwq-icon(dollar, false);
  }

  .vwq-icon-edit--n
  {
    @include vwq-icon(edit, false);
  }

  .vwq-icon-plus--n
  {
    @include vwq-icon(plus-micro, false);
  }
  .vwq-icon-close--o
  {
    @include vwq-icon(unavailable, false);
  }

  .vwq-icon-my-bookings
  {
    @include vwq-icon(receipt, false);
  }
  
  .vwq-icon-close--n
  {
    @include vwq-icon(close, false);
  }
  
  .vwq-icon-arrow-double
  {
    @include vwq-icon(doublechevron-forward-micro, false);
  }
  
  .vwq-icon-arrow-left
  {
    @include vwq-icon(chevron-left-micro, false);
  }

  .vwq-icon-arrow-right
  {
    @include vwq-icon(chevron-right-micro, false);
  }

  .vwq-icon-info
  {
    @include vwq-icon(warning-micro, false);
  }

  .vwq-icon-info--o
  {
    @include vwq-icon(warning, false);
  }
  .vwq-icon-refresh--o
  {
    @include vwq-icon(refresh-micro, false);
  }

  .vwq-icon-accommodations
  {
    @include vwq-icon(hotel, false);
  }
  
  .vwq-icon-price--n
  {
    @include vwq-icon(dollar, false);
  }

  .vwq-icon-hotel
  {
    @include vwq-icon(destination, false);
  }
  
  .vwq-icon-packages
  {
    @include vwq-icon(vacation, false);
  }

  .vwq-icon-deals
  {
    @include vwq-icon(deals-solid, false);
  }
}

.group-information-container .Localize-text {
  font-size: var(--grid-1x)!important;
  margin-bottom: 0!important;
}

.formControl {
  width: 100%;
  box-sizing: border-box;
}

.bg-white {
  background-color: #fff !important;
}

.notice-Of-confidentiality-display-text {
  margin: calc(var(--grid-2x)) 0;
}

.text-right-align {
  text-align: right;
}


html[data-brand=vwq] {
  .product-type-icon {
      padding: inherit;
  }
}