.search-container {
    width: 100%;
    overflow: hidden;
}

.search-head {
    width: auto;
    padding: var(--grid-1x);
    background: var(--onyx-bg);

    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}

.search-row {
    width: 100%;
    overflow: hidden;
}

.screen-quote-options {
    display: none;
}

.search-contents {
    width: 100%;
    padding: var(--grid-1x);
    background: var(--onyx-bg);
    
    -webkit-border-radius: calc(var(--grid) / 2);
    -webkit-border-top-left-radius: 0;
    -moz-border-radius: calc(var(--grid) / 2);
    -moz-border-radius-topleft: 0;
    border-radius: calc(var(--grid) / 2);
    border-top-left-radius: 0;
    overflow: hidden;
}


.search-contents .MuiOutlinedInput-input,
.search-contents .MuiSelect-select {
    background: var(--white);
}

.search-text {
    width: auto;
}

.grid-container {
    width: 100%;
    border: 1px solid var(--onyx-brdr);
    border-radius: 4px;
    overflow: hidden;
}

.grid-head {
    width: 100%;
    color: var(--white);
    background: var(--grid-head-bg);
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}

.grid-row {
    width: 100%;
    border-bottom: 1px solid var(--onyx-brdr);
}

.grid-row:hover {
    background: var(--ocean-blue-opacity-10);
}

.grid-row:last-child {
    border-bottom: 0!important;
}

.grid-add-option {
    padding: 0!important;
    font-size: 12px!important;
    margin: 0!important;
    min-width: auto!important;
}

.quote-number {
    width: 104px;
    padding: var(--grid-1x);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.quote-group-name {
    width: 120px;
    padding: var(--grid-1x) var(--grid);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid-row button {
    /* width: 100%; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.quote-created-on {
    width: 110px;
    padding: var(--grid-1x) var(--grid);
}

.quote-flight-icon {
    width: var(--grid-2x);
    text-align: center;
}

.quote-status-confirmed {
    width: auto;
    padding: 0 var(--grid);
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
    color: var(--white);
    background: var(--grid-quote-status-bg);
}

.quote-options-container {
    width: calc(100% - 400px);
    padding: var(--grid-1x) var(--grid);
}

.quote-options {
    width: 112px;
}

.product-type {
    width: calc(100% - 25px);
}

.quote-gateway, .quote-destination {
    width: calc(50% - 244px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.quote-hotel-or-cruise-container {
    width: 176px;
}

.quote-hotel-or-cruise {
    width: calc(100% - 32px);
}

.quote-expiry-date {
    width: 112px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.quote-action {
    width: 54px;
    padding: var(--grid-1x) 0;
    text-align: center;
}

.quote-options-container .margin-bottom-1x:last-child {
    margin-bottom: 0!important;
}

.more-container {
    width: 24px;
}

.more-button {
    width: 24px;
    height: 24px;
    text-align: center;
    color: var(--grid-link-button-fg);
}

.more-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.hotel-pop-over-row {
    width: 100%;
    padding: 4px 16px;
}

.error-pop-over-row {
    width: 100%;
    padding: 8px 16px;
    color: var(--error-fg);
}

.showOrHide {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--white);
}

.quote-number .add-quote-modal {
    white-space: normal;
}

.pagination-box{
    width: 100%;
    margin-top: 16px;
}

.search-option-btn-container{
    .MuiButtonBase-root{
        padding: 4px 16px;
        border-radius: 4px;
    }
    .cancel-btn{
        color: var(--grid-link-button-fg);
    }
    .search-btn{
        color: var(--white);
    }

}

.add-option{
    .add-option-link{
        color: var(--grid-link-button-fg);
    }

}
@media (max-width:1024px) {
    .quote-status-confirmed {
        margin-left: 10px;
    }
}

@media (max-width:767px) {
    .search-head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        span.swg-icon {
            margin-top: 8px;
        }
    }
    .display-all-checkbox {
        width: 100% !important;
    }
    .grid-head {
        display: flex;
        justify-content: space-around;
    }
    .quote-number {
        width: auto !important;
    }
    .quote-group-name {
        width: 120px !important;
    }
    .quote-created-on, .quote-options-container {
        display: none;
       
    }
    .add-option {
        display: flex;
        justify-content: space-around;
    }
    .search-contents .box-sizing {
    width: 100%;
    }
}
