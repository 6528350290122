.view-quote-product-type .hotel-details {
    width: calc(100% - (240px + var(--grid-1x)));
    margin-right: 0;
} 

.view-quote-product-type-row h3 {
    color: var(--itinerary-heading-fg);
    margin-top: 8px !important;
	margin-bottom: 8px !important;
}

.view-quote-product-type-row {
    width: 100%;
    border-radius: 4px;
    padding: var(--grid);
    box-sizing: border-box;
    background: var(--itinerary-heading-bg);
    color: var(--itinerary-heading-fg);
    overflow: hidden;
}

.product-row {
    padding-bottom: var(--grid-1x);
    margin-bottom: var(--grid-1x);
    border-bottom: 1px solid var(--onyx-brdr);
}

@media (min-width: 300px) and (max-width: 767px) {
    .col-option-gateway h3 {
        font-size: 17px;
        margin-bottom: 0;
        margin-top: 5px;
        font-weight: bold  !important;
    }
    .col-option-text h3 {
        margin-top: 0; 
        font-size: 17px;
        font-weight: bold  !important;
    }
}
