.representative-information-container {
    width: 100%;
    margin-bottom: var(--grid-3x);
}

.representative-information-container .link-button {
    padding: 4px 0!important;
}

.representative-information-container .localize-text {
    font-size: var(--grid-1x)!important;
    margin-bottom: 0!important;
}