
.promotion-tile {
    width: calc(50% - var(--grid));
    padding: var(--grid-1x);
    background: var(--white);
}

.promotion-tile-undo{
    width: calc(50% - var(--grid));
    padding: var(--grid-1x);
}

.promotion-tile-undo:nth-child(odd) {
    margin-right: var(--grid-1x);
}
  
.promotion-tile-undo h3 {
    font-weight: 700!important;
    height: var(--grid-7x);
    vertical-align: middle;
    margin: 0;
    display: table-cell;
}

.promotion-tile:nth-child(odd) {
    margin-right: var(--grid-1x);
}
  
.promotion-tile h3 {
    font-weight: 700!important;
    height: var(--grid-7x);
    vertical-align: middle;
    margin: 0;
    display: table-cell;
}


.promotion-icon-undo {
    width: var(--grid-7x);
    height: var(--grid-7x);
    padding: var(--grid-1x);
    text-align: center;
    float: left;
    box-sizing: border-box;
    border-radius: 50%;
    background: var(--onyx3);
    margin-right: var(--grid-2x);
}

.promotion-icon-undo .icon {
    font-size: 32px;
}

.items { 
    display: inline-table;
    margin: var(--grid-1x) 0 0 0;
    width: 100%;
}

.promotion-icon {
    width: var(--grid-7x);
    height: var(--grid-7x);
    float: left;
    padding: var(--grid-1x);
    text-align: center;
    box-sizing: border-box;
    border-radius: 50%;
    background: var(--light-orange);
    margin-right: var(--grid-2x);
}

.promotion-icon .icon {
    font-size: 32px;
}

html[data-brand=vwq] {
    .promotion-tile h3 {
        color: var(--black) !important;
    }
}

.cross-button{
    position: relative;
    left: 4px;
    float: right !important;
    bottom: 80px;
}

.border-promotion{
    border: 1px solid var(--black);
    border-radius: 6px;
}