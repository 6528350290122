.emailAddress-col {
    width: calc(100% - 56px);
    margin-right: var(--grid-1x);
}

.emailAddress-action-col {
    width: 40px;
}

.add-button,.remove-button{
    min-width: 100%!important;
    height: 40px;
    padding: var(--grid)!important;
}

.remove-button{
    color: var(--error-fg)!important;
    border: 1px solid var(--error-fg)!important;
}
.remove-email{
    display: inline;
    text-decoration: underline;
    color: var(--error-fg);
    font-size: 14px;
}
.remove-email:hover{
    cursor: pointer
}
.email-list-items{
    padding: 8px 0;
    border-bottom: 2px solid var(--onyx-brdr);
    margin-bottom: 8px;
}

.email-error-message{
    color: var(--error-fg) !important;
}
.add-button.Mui-disabled{
    color: var(--onyx-brdr) !important;
    border: 1px solid var(--onyx-brdr) !important;
}