.view-group-itinerary-container {
    width: 100%;
}

.view-quote-itinerary-concession-col-text-min {
    width: 100px;
    display: table-cell;
    height: 100%;
}
.view-quote-itinerary-concession-col-text-mid {
    width: 130px;
    display: table-cell;
    height: 100%;
}
.view-quote-itinerary-concession-col-text-plus {
    width: 150px;
    display: table-cell;
    height: 100%;
}

.view-quote-itinerary-concession-col-min-plus {
    width: 15.5%;
    display: table-cell;
    height: 100%;
}

.view-quote-itinerary-concession-col-medium {
    width: 25%;
    display: table-cell;
    height: 100%;
}

.view-quote-itinerary-updated-by {
    width: 160px;
    padding: 0 8px 0 16px;
    box-sizing: border-box;
}

.view-quote-itinerary-updated-on {
    width: 180px;
    padding: 0 8px 0 16px;
    box-sizing: border-box;
}

.view-quote-itinerary-log {
    width: 720px;
    padding: 0 8px 0 16px;
    box-sizing: border-box;
}

.view-quote-itinerary-description {
    width: calc(100% - 160px);
    padding: 0 16px 0 16px;
    box-sizing: border-box;
}

.btn-green {
    background: var(--btn-green-bg) !important;
    height: 38px;
}


.table {
    width: calc(100% - 2px);
    display: table;
    height: 100%;
    border: 1px solid var(--onyx-brdr);
    border-radius: 4px;
}

.dark-bg {
    background: var(--onyx2) !important;
    color: var(--white);
}

.table-head {
    width: 100%;
    background: var(--onyx-bg);
    border-bottom: 2px solid var(--white);
    line-height: 18px;
    > .table-row {
        height: var(--grid-8x);
    }
    > .table-row.small-row {
        height: var(--grid-5x);
    }
}

.add-button {
    display: inline-block !important;
}
.table-body {
    width: 100%;
    background: var(--onyx-bg);
    overflow: hidden;
}

.table-row {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: table-row;
}

.itinerary-information-container .table-body .table-row:hover{
    background: var(--onyx-bg);
}

.table-body .table-row:hover{
    background: var(--white);
}

.table-head .table-column {
    padding: var(--grid-1x) var(--grid);
}

.table-column {
    vertical-align: top;
    display: table-cell;
    height: 100%;
    padding: var(--grid);
    box-sizing: border-box;
}

.itinerary-information-container .table-column {
    padding: 2px var(--grid);
}

.itinerary-information-container .first-row .table-column-min:first-child {
    width: 12.5%;
}

.itinerary-information-container .first-row .table-column-min:nth-child(3) {
    width: 40%;
}

.itinerary-information-container .first-row .table-column-min:nth-child(4) {
    width: 12.5%;
}

.itinerary-information-container .first-row .table-column-min:last-child {
    width: 15%;
}

.table-column-min {
    width: 12.5%;
    display: table-cell;
    height: 100%;
}

// .column-min {
//     width: 6%;
//     height: 70%;
// }
.table-column-small {
    width: 10%;
    display: table-cell;
    height: 100%;
}

.itinerary-information-container {
    width: 100%;
    border: none;
}

.itinerary-information-container:first-child {
    border-bottom: 2px solid var(--white);
}

.itinerary-information-container .table-body {
    padding: var(--grid);
    box-sizing: border-box;
}

.itinerary-information-container .table-column-min {
    width: 20%;
}

.margin-override .table-column-large {
    width: 50%;
    display: table-cell;
    height: 100%;
}

.margin-override .table-column-min {
    width: 25%;
    display: table-cell;
    height: 100%;
}

.margin-override .table-row-h-text {
    width: 100px;
    float: left;
    padding: 8px 0;
    box-sizing: border-box;
}

.margin-override .table-row-h-input {
    width: calc(100% - 100px);
    float: left;
}

.table-column-large {
    width: 37.5%;
    display: table-cell;
    height: 100%;
}

.table-row-h-text {
    width: 40px;
    float: left;
    padding: 8px 0;
    box-sizing: border-box;
}

.table-row-h-input {
    width: calc(100% - 40px);
    float: left;
}

.view-group-itinerary-container Section {
    margin: 0;
    padding: 0;
}

.text-transparent {
    color: transparent;
}

.itinerary-notes {
    width: 100%;
    border: 1px solid var(--onyx-brdr);
    border-radius: 4px;
    padding: 0;
    box-sizing: border-box;
}

.itinerary-notes .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
}

.itinerary-notes .MuiInputBase-root {
    height: inherit;
}

.table-pricing-col-text {
    width: 240px;
    display: table-cell;
    height: 100%;
}

.ebb-value {
    width: 140px;
    margin-left: var(--grid);
}

.table-pricing-col-input {
    width: 180px;
    display: table-cell;
    height: 100%;
}

.freeze-column {
    width: 240px;
    float: left;
}

.scroll-container {

  scrollbar-color: #d5ac68 #f1db9d;
  scrollbar-width: thin;
  -ms-overflow-style: none; 
  width: calc(100% - 240px);

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  float: left;
}

.concession-container {
    overflow: hidden;
}
.concession-container.scroll-container {
    float: none;
    overflow-x: scroll;
}

.scroll-columns {
    width: 3500px;
}

.pricing-details-container .table-head .table-column {
    padding: var(--grid-1x) var(--grid);
    height: 72px;
    word-wrap: break-word;
}

.pricing-details-container .table-body .table-column {
    height: 56px;
}

.container-border {
    width: 100%;
    border: 1px solid var(--onyx-brdr);
}

.pricing-details-section {
    background: var(--onyx-bg);
}

.container-bg {
    background: var(--white);
}

.itinerary-cal-icon{
    position: relative;
    float: right;
    right: 12px;
    font-size: 16px !important;
}

html[data-brand=vwq] {
    .itinerary-cal-icon{
        bottom: 30px;
    }
}

html[data-brand=swg] {
    .itinerary-cal-icon{
        bottom: 26px;
    }
}

.date-picker-itinerary{
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-weight: 400;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

}

.title-popup > .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100
}

.concession-detail-heading .Mui-checked {
    color: var(--white)!important;
}

.concession-detail-heading .MuiSwitch-track {
    background-color: var(--onyx3);
    height: 20px;
    margin-top: -5px;
    border-radius: 10px!important;
}

.view-group-itinerary-container .add-button {
    width: 44px;
    height: 44px;
    padding: var(--grid) !important;
}