.profile-information-container {
    width: 100%;
    margin-bottom: var(--grid-3x);

    .heading {
        width: 20%;
      }
    
    .value {
        width: 80%;
    }
}

.profile-information-container .link-button {
    padding: 4px 0!important;
}

.profile-information-container .Localize-text {
    font-size: var(--grid-1x)!important;
    margin-bottom: 0!important;
}

.column-min {
    width: 6%;
    height: 70%;
}

@media (min-width: 300px) and (max-width: 767px) {
    .add-quote .heading {
        width: 60% !important;
    }
}
