.user-menu {
    width: 100%;
}

.user-menu div {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    cursor: pointer;
    color: var(--app-header-user-menu-fg) !important;
    text-align: var(--app-header-user-menu-text-align);
}

.user-menu div:hover {
    background-color: var(--onyx-bg);
    cursor: pointer;
}

header ul li a.active::after {
    bottom: -32px;
    position: absolute;
    left: 50%;
    border: 10px solid transparent;
    border-bottom-color: currentcolor;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
}

header ul li .active {
    position: relative;
}

a {
    background-color: transparent;
    text-decoration: none;
}

header ul li a {
    font-weight: 700;  
    font-size: calc(var(--grid) * 2.38);
    margin-top: -8px;
}

.Header_module_headerNav__43492327 {
    margin-top: 0px;
}

html[data-brand=vwq] {

    .Okta_module_dropdownMenu__cc09682b {
        min-width: 200px !important;
    }

    .logo-container {
        padding: 16px;
        min-width: 232px !important;
    }
    .app-header > header > section {
        background-color: var(--app-header-bg) !important;
    }
    
    .app-header > header > section a {
        color: var(--app-header-link-fg) !important;
    }
    .app-header > header > section button {
        color: var(--app-header-button-fg) !important;
    }
    .app-header > header > section a.active:after {
        color: var(--app-header-link-active-fg) !important; 
    }
    
    .app-header > header > section img {
        max-height: 100% !important;
    }

    .Okta_module_profileText__cc09682b .swg-icon {
        display: none !important;
    }

    .Okta_module_triggerDDMenu__cc09682b > span {
        float: right !important;
        position: relative !important;
        font-weight: 300;
        color: var(--black);
    }

    .Okta_module_triggerDDMenu__cc09682b > span.swg-icon {
        color: var(--deep-aqua0) !important;
    }

    .Okta_module_triggerDDMenu__cc09682b > span:nth-of-type(1) {
        right: -80%;
    }

    .Okta_module_triggerDDMenu__cc09682b > span:nth-of-type(2) {
        right: 20%;
    }

    header ul li a {
        font-weight: 400;
    }

    .Header-module--headerBtnInfoWrapper--3XFZg {
        border: 1px solid;
        border-radius: 100%;
        color: #fff;
        display: block;
        height: 1.875rem;
        position: relative;
        width: 1.875rem;
    }
}
