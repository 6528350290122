.popup-content {
  background: var(--onyx-bg);
  display: block;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: #000;
  border: 1px solid #ccc;
}

.popup-content .table {
  border: none !important;
}

.popup-content .table-body {
    width: 100%;
    overflow: hidden;
    display: table;
}

.popup-table-row .column-label {
  vertical-align: middle;
}

.popup-content .button-line {
  text-align: center;
  margin: var(--grid-1x) 0;
  button.MuiButton-root {
    margin: 0 var(--grid);
    color: var(--white);
  }
}

.popup-table-row {
  width: 100%;
  height: 100%; 
  display: table-row;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px red solid;
  .column-label {
    vertical-align: middle;
    width: 100px;
  }
  .table-pricing-col-input {
    width: 300px;
  }
} 

.popup-error {
  color:red;
  margin: var(--grid-1x);
}