.modal {
  :global {
    .popup-container-bg {
      background-color: var(--onyx);
    }
    
    .popup-container {
      min-height: 275px;
      display: flex;

      .popup-container-border {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .icon {
          color: var(--onyx3);
          position: absolute;
          top: 24px;
          right: 24px;
          cursor: pointer;
        }
        .back-button {
          color: #fff !important; padding: 15px !important;
        }
      }
    }
  }
}
