.deviation-flight-col {
    width: calc(100% - 40px) !important;
    padding: 2px;
    float: left;
}

.deviation-action-col {
    width: 40px;
    float: right;
}

.deviation-flight-input {
    width: calc(100% - var(--grid-1x)) !important;
}

.itinerary-pricing-col {
    width: 180px;
    float: left;
    box-sizing: border-box;
}

.itinerary-pricing-col-desc {
    width: calc(100% - 200px);
    float: right;
    box-sizing: border-box;
}

.price-cad{
    width: 65px;
    font-size: 14px !important;
}