.view-quote-grid-container {
    width: 100%;
    overflow: hidden;
}

.view-quote-date-duration .Localize-text {
    font-size: var(--grid-1x)!important;
    margin-bottom: 0!important;
}

.view-quote-grid-head, .view-quote-grid-body {
    width: 100%;
    background: var(--onyx-bg);
    padding: var(--grid-2x) 0;
    overflow: hidden;
}

.view-quote-grid-body {
    padding: 0;
}

.view-quote-grid-head {
    font-weight: 700;
}

.view-quote-option, .view-quote-created-on, .view-quote-date-duration, 
.view-quote-destination-departure, .view-quote-product-itinerary, 
.view-quote-available-unavailable, .view-quote-active-inactive, .view-quote-clone-option, .product-itinerary {
    float: left;
}

.view-quote-grid-row {
    width: 100%;
    border-top: 2px solid var(--white);
    padding-top: 16px;
    overflow: hidden;
}

.view-quote-add-row {
    width: 100%;
    border-top: 2px solid var(--white);
}

.view-quote-product-itinerary .header-link-button {
    width: calc(100% - 200px);
    padding: 0 !important;
}

.view-quote-grid-row .link-button {
    padding: 0 !important;
    overflow: hidden;
    margin-top: 6px !important;
    display: block;
    height: auto;
}

.product-name {
    width: 100%;
    padding: 0 0 8px 0;
}

.view-quote-grid-row .link-button .row {
    margin-top: 8px;
}

.view-quote-option {
    width: 108px;
    padding: 0 8px 0 16px;
}

.view-quote-created-on {
    width: 120px;
    padding: 0 8px;
}

.view-quote-date-duration {
    width: 140px;
    padding: 0 8px;
}

.view-quote-destination-departure {
    width: 135px;
    padding: 0 8px;
}

.view-quote-product-itinerary {
    width: calc(100% - 576px);
    /* padding: 0 16px; */
}

.product-itinerary {
    width: calc(100% - 208px);
}

.view-quote-available-unavailable {
    width: 112px;
    text-align: center;
}

.itinerary-number{
    width: 16px;
}

.view-quote-active-inactive {
    width: 96px;
    text-align: center;
    box-sizing: border-box;
}

html[data-brand=vwq]
{
    .view-quote-available-unavailable {
        width: 110px;
    }
    .view-quote-active-inactive {
        width: 98px;
    }
    .view-quote-option {
        padding: 0 2px 0 16px;
    }
}

.view-quote-clone-option {
    width: 72px;
    text-align: center;
}

.clone-wrapper {
    width: 22px;
    height: 22px;
    margin: 8px auto 0 auto;
    border-radius: 4px;
    border: 2px solid var(--onyx2);
    color: var(--onyx2);
    opacity: 0.5;
    cursor: pointer;
}

.clone-container {
    width: 22px;
    height: 22px;
    margin: -6px auto 0 4px;
    border-radius: 4px;
    border: 2px solid var(--onyx2);
    color: var(--onyx2);
    background: var(--white);
    cursor: pointer;
}

.available-container, .unavailable-container {
    width: 22px;
    height: 22px;
    margin: 8px auto 0 auto;
    border-radius: 4px;
}

.available-container {
    border: 2px solid var(--available-container-fg);
    color: var(--available-container-fg);
}

.unavailable-container {
    border: 2px solid var(--onyx-brdr);
    color: var(--onyx-brdr);
}

.available-unavailable-icon {
    width: auto;
    padding: 0 3px;
    margin-top: -4px;
    font-size: 12px;
}

.clone-icon {
    width: auto;
    padding: 0 3px;
    margin-top: -4px;
    font-size: 12px;
}

.view-quote-active-inactive .Mui-checked {
    color: var(--white)!important;
}

.Mui-checked+.MuiSwitch-track {
    background-color: var(--onyx)!important;
}

.view-quote-active-inactive .MuiSwitch-track {
    background-color: var(--onyx3);
    height: 20px;
    margin-top: -5px;
    border-radius: 10px!important;
}

.MuiSwitch-thumb {
    width: 16px!important;
    height: 16px!important;
}

.MuiSwitch-root {
    width: 64px!important;
}

.MuiSwitch-switchBase {
    padding: 9px 14px!important;
}

.margin-header-confirmed {
    margin-top: 2px;
    margin-left: var(--grid-1x);
}

.switch-header .MuiSwitch-root {
    width: 24px!important;
    height: 24px!important;
    padding: 10px 0!important;
}

.switch-header .Mui-checked .MuiSwitch-thumb {
    margin-left: -6px;
}

.switch-header .MuiSwitch-switchBase {
    padding: 7px 1px!important;
}

.switch-header .MuiSwitch-track {
    height: 10px;
    margin-top: -4px!important;
}

.MuiSwitch-track {
    border: 1px solid var(--onyx-brdr);
}

.switch-header .MuiSwitch-thumb {
    width: 8px!important;
    height: 8px!important;
}