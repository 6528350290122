.progress-indicator-container {
    width: 100%;
    height: var(--grid);
    background: var(--onyx-brdr-opacity-30);

    -webkit-border-top-left-radius: calc(var(--grid) / 2);
    -webkit-border-top-right-radius: calc(var(--grid) / 2);
    -moz-border-radius-topleft: calc(var(--grid) / 2);
    -moz-border-radius-topright: calc(var(--grid) / 2);
    border-top-left-radius: calc(var(--grid) / 2);
    border-top-right-radius: calc(var(--grid) / 2);
}

.progress-indicator {
    height: var(--grid);
    background-color: var(--progress-indicator-bg);

    -webkit-border-top-left-radius: calc(var(--grid) / 2);
    -webkit-border-top-right-radius: calc(var(--grid) / 2);
    -moz-border-radius-topleft: calc(var(--grid) / 2);
    -moz-border-radius-topright: calc(var(--grid) / 2);
    border-top-left-radius: calc(var(--grid) / 2);
    border-top-right-radius: calc(var(--grid) / 2);
}
