
.product-type-icon {
    width: auto;
    padding: calc(var(--grid) / 2) 0;
  }
  
  html[data-brand=vwq] {
    .product-type-icon {
        padding: inherit;
    }
}