.inVoice-container {
    flex-basis: 50%;
}

.inVoice-container.label {
    text-align: right;
    margin-right: 10px;
    div {
        vertical-align: middle !important;
        display: inline-block !important;
        margin-top: 6px;
    }
}

.inVoice-container.button {
    text-align: left;
    margin-left: 10px;
}

.email-container{
    text-align: center;
    display: flex;
    justify-content: center;
}

.email-error{
    justify-content: center;
    text-align: center !important;
}
