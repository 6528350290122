
.promotions-container {
    width: 100%;
}

.outer-block { 
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: var(--grid-1x);
    -webkit-column-gap: var(--grid-1x);
    column-gap: var(--grid-1x);
}
