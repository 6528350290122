.manual-quote {
    color: var(--manual-quote-fg) !important;
}

.row-additional-information.add-info .MuiInputBase-root {
    height: auto !important;
}
.add-option-btn-container{
    .MuiButtonBase-root{
        color: var(--white);
        padding: 4px 16px;
        border-radius: 4px;
    }
    
}

.edit-cancel-btn{
    .MuiButtonBase-root{
        color: var(--edit-cancel-btn-fg);
    }
}

@media (max-width:480px) {
    .add-option-btn, .add-get-quote-btn {
        width: 100%;
    }  
    .edit-cancel-btn {
        display: flex;
        align-items: flex-start;
    }
    .add-option-btn-container .pull-right {
        width: 100%;
    }
}

.option-header {
    justify-content: space-between;
}

@media (max-width:390px) {
    .option-header {
       flex-direction: column;
    }
    .edit-cancel-btn {
    margin-bottom: 10px
    }
}

